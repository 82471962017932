<!-- @Author: Yu_Bo -->
<template>
	<div class='image_amplify'>
		<div class="image_box_main" v-if="dialogVisible">
		  <div class="main_image">
        <img :src="img" alt="">
		    <div class="img_close">
		      <div class="close" @click="closeBtn"><i class="el-icon-close"></i></div>
		    </div>
		  </div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
				dialogVisible:false,
        img:''
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开
      openDialog(val){
        this.img = val
        this.dialogVisible=true
      },
      // 关闭
      closeBtn(){
        this.dialogVisible=false
      },
    },
	}
</script>

<style lang='scss' scoped>
	.image_amplify{
		width: 100%;
    .image_box_main{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000000;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      .main_image{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
          width: 500px;
          max-height: 500px;
        }
        .img_close{
          width: 100%;
          /* max-width: 30%; */
          padding-top: 30px;
          .close{
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin: auto;
            font-size: 24px;
            color: #fff;
            background: rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            .el-icon-close{
              font-size: 26px;
            }
          }
        }
      }
    }
	}
</style>
